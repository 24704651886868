import { init } from "marko/dist/runtime/components/index.js";
import "../../../assets/tarry-tracker.webp";
import "../../../assets/tarry-theme-red-orange.webp";
import "../../../assets/tarry-invoice-preview.webp";
import "../../../assets/tarry-tasks.webp";
import "../../../assets/tarry-theme-green.webp";
import "../../components/app-layout.marko.css!=!/home/hunter/tarry-website/node_modules/@marko/webpack/dist/loader/index.js!/home/hunter/tarry-website/src/components/app-layout.marko?virtual=./app-layout.marko.css";
import "../../../assets/icon.png";
import "../../components/hero.marko.css!=!/home/hunter/tarry-website/node_modules/@marko/webpack/dist/loader/index.js!/home/hunter/tarry-website/src/components/hero.marko?virtual=./hero.marko.css";
import "./components/section.marko.css!=!/home/hunter/tarry-website/node_modules/@marko/webpack/dist/loader/index.js!/home/hunter/tarry-website/src/pages/index/components/section.marko?virtual=./section.marko.css";
import "./components/quote.marko.css!=!/home/hunter/tarry-website/node_modules/@marko/webpack/dist/loader/index.js!/home/hunter/tarry-website/src/pages/index/components/quote.marko?virtual=./quote.marko.css";
import "../../components/footer.marko.css!=!/home/hunter/tarry-website/node_modules/@marko/webpack/dist/loader/index.js!/home/hunter/tarry-website/src/components/footer.marko?virtual=./footer.marko.css";
import "feather-marko/components/feather-icon.marko";
init();